import React, { FunctionComponent } from "react"
import styled from "styled-components"
import theme from "./theme"

interface MainGridProps {
  noPaddingMobile?: boolean
  noPaddingTablet?: boolean
  borderTop?: boolean
}
export const MainGrid: FunctionComponent<MainGridProps> = ({
  children,
  noPaddingMobile,
  noPaddingTablet,
  borderTop,
}) => {
  return (
    <Container noPaddingMobile={noPaddingMobile} noPaddingTablet={noPaddingTablet} borderTop={borderTop}>
      {children}
    </Container>
  )
}
export default MainGrid

const Container = styled.section<MainGridProps>`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-top: ${props => props.borderTop && `1px solid ${theme.grey.keylineDark}`};
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 3%;
  }
  @media (max-wdith: 1024px) {
    padding: ${props => (props.noPaddingTablet ? "0px" : "0 3%")};
  }
  @media (max-width: 767px) {
    padding: ${props => (props.noPaddingMobile ? "0px" : "0 20px")};
  }
`
